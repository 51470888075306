import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const Landingpage = () => import('../views/HomePage.vue');
const PlattenSet = () => import('../views/SetPage.vue');
const Rechtliche = () => import('../views/LegalPage.vue');


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Landingpage
  },
  {
    path: '/set',
    name: 'plateSet',
    props: {
      url: {type: String, default:""}
    },
    component: PlattenSet
  },
  {
    path: '/legal/:url',
    name: 'legal',
    props: {
      url: {type: String, required: true}
    },
    component: Rechtliche
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      if(to.hash === "#!/~/cart" || from.hash === "#!/~/cart") return;

      return savedPosition
    }else{
      if(to.hash === "#!/~/cart" || from.hash === "#!/~/cart") return;

      return { top: 0 }
    }
  }
})
export default router
