
import {defineComponent, ref} from "vue";
import pageCMS from "@/modules/cmsRest";

export default defineComponent({
  name: "FooterMod",
  data(){
    return{
      social : [
        {name: "Instagram", label:"ballerina.berlin", link: "https://instagram.com/ballerina.berlin", icon: "instagram"},
        // {name: "Facebook", label: "ballerina.berlin", link: "https://facebook.com", icon: "facebook"}
      ],
      contactData : [
        {name: "info@ballerina.berlin", link: "mailto:info@ballerina.berlin", icon: "envelope"},
        {name: "+49 (0) 152 38 032 891", link: "tel:04915238032891", icon: "phone-flip"}
      ],
      legalLinks: [] as Array<any>
    }
  },
  props:{
    cmsObject: {
      type: Object,
      required: false
    },
  },
  watch:{
    cmsObject(){
      console.log("Props for cms loaded successfully");
      this.requestCMS();
    }
  },
  methods:{
    requestCMS(){
      if ((this.$props.cmsObject as pageCMS).ready === false) return;

      const cms = this.$props.cmsObject as pageCMS;
      const cmsSelect = {
        select: ["url", "Page_title"]
      };
      cms.request("ballerina_legal", true, cmsSelect)
          .then((response) => {
            if(typeof response.entries !== "undefined" && response.entries.length > 0){
              for(var x in response.entries){
                const currPageLink = response.entries[x] as any;
                this.legalLinks.push({
                  title : currPageLink.Page_title,
                  link : currPageLink.url
                } as any);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }
});
