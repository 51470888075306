
import {defineComponent, ref} from "vue";

import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import SplitText from "gsap/SplitText";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import ScrollButtonMod from "@/components/ScrollButtonMod.vue";

export default defineComponent ({
  name: "HeroMod",
  components: {ScrollButtonMod},
  data(){
    return{
      heroContent: ref<HTMLDivElement>(),
      strawMove: 0,
      strawLift : {
        threshold: 0.8,
        distance: 100,
      } as any,
      animTimer : ref<ReturnType<typeof setInterval>>(),
      timerPurc: 0,
      isTouch : true,
      animDone: false,
      firstMove: true,
      lineWidth: .5,
    }
  },
  mounted(){
    this.heroContent = this.$refs.heroCenter as HTMLDivElement;

    gsap.fromTo('.animContainer',{
      y: 200,
      autoAlpha: 0
    },{
      y: 0,
      autoAlpha: 1,
      duration: 2,
      stagger: -1,
      delay: 0.5,
      ease: "power2.out",
      onComplete: this.startPreAnim
    })
  },
  watch:{
    title(){
      if(this.$props.title.length < 1) return;

      // animate IN
      window.setTimeout(() => {
        this.centerHeroContent();

        const headlineChars = new SplitText('.heroTxt h1', {type: "lines, chars"});
        gsap.fromTo(headlineChars.chars,{
          autoAlpha: 0,
          yPercent: -150,
          rotate: -25,
          scale: 2
        },{
          autoAlpha: 1,
          yPercent: 0,
          rotate: 0,
          scale: 1,
          delay: 1,
          duration: 1,
          stagger: 0.05,
          ease: "power2.out",
          onComplete: () => {
            headlineChars.revert();
          }
        });

        console.log("text loaded");
      },1);
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    image: {type: String, required: true}
  },
  activated(){
    gsap.registerPlugin(CSSRulePlugin);
    gsap.registerPlugin(ScrollToPlugin);


    // touch Timer oder Mouse Listener setzten
    this.isTouch = (window.matchMedia("(pointer: coarse)").matches);

    console.log("Touch?", this.isTouch ? "yes" : "no");

    this.centerHeroContent();
    this.strawAnim(1);



    window.addEventListener("resize", this.resizeWindow);
  },
  deactivated() {
    window.removeEventListener("resize", this.resizeWindow);

    window.clearInterval(this.animTimer);
  },
  methods: {
    scrollDown(){
      gsap.to(window, {
        duration: .5,
        scrollTo: {
          y           :   '.offering',
          autoKill    :   false
        },
        ease: "power1.out"
      });
    },
    resizeWindow(){
      this.centerHeroContent();
    },
    centerHeroContent() {
      if(!this.heroContent) return;

      const fullHero = this.heroContent.parentNode as HTMLDivElement;
      const innerHeight = document.documentElement.clientHeight;
      gsap.set(fullHero, {height: innerHeight});

      const fullSpace = innerHeight - this.heroContent.offsetHeight;
      const offset = (window.innerWidth < 1600) ? fullSpace - 50 : fullSpace / 2;

      gsap.set(this.heroContent, {
        y: offset
      });

      this.setStrawMoveDistance();
    },
    setStrawMoveDistance(){
      let strawCont = (this.$refs.strawContainer as HTMLDivElement);
      this.strawMove = strawCont.offsetWidth - (strawCont.lastElementChild as HTMLDivElement).offsetWidth;
    },
    mouseMove(evt: any){
      if(!this.animDone) return;

      if(this.isTouch) return;

      if(!this.firstMove && this.animTimer){
        window.clearInterval(this.animTimer);
        this.animTimer = undefined;
      }

      const purcMove : number = (1-(evt as MouseEvent).pageX / window.innerWidth);
      if(purcMove > .95) this.resetPowerLine();
      this.strawAnim(purcMove);
    },
    resetPowerLine(){
      this.lineWidth = .5;
      gsap.to((this.$refs.strawContainer as HTMLDivElement).children[1], {
        autoAlpha: 1,
        width: "50%",
        duration: .2,
        overwrite: true
      });
    },
    startPreAnim(){
      // start animation
      const steps = 100;
      const timeout = 15;
      this.animTimer = window.setInterval(() => {

          // animate strawAnim with pure floatVal
          if (this.timerPurc < steps + timeout) this.timerPurc++;
          else this.timerPurc = 0;

          if (this.timerPurc > steps) {
            if(!this.animDone) this.animDone = true;
            if(this.firstMove) this.firstMove = false;
            this.resetPowerLine();
            return;
          }

          const animPurc = Math.pow(Math.abs((this.timerPurc - (steps / 2)) * 2) / steps, 3);
          this.strawAnim(animPurc);
          // von 0 bis 100 bis 0
        }, 50);
    },
    strawAnim(purc: number) {
      // upwards
      const lastDigit = (purc > this.strawLift.threshold) ? parseFloat(((purc - this.strawLift.threshold) * (1/(1-this.strawLift.threshold))).toFixed(2)) : 0;
      const negLastDigit = 1 - lastDigit;

      const straw = (this.$refs.strawContainer as HTMLDivElement).firstElementChild as HTMLDivElement;
      // Move Straw
      gsap.set(straw, {
        x: this.strawMove * purc,
        y: -lastDigit * this.strawLift.distance,
        rotate:  -15 + (purc * 30)
      });

      const PerspChange = (negLastDigit *8) + 8;
      // Transform Straw perspective
      gsap.set((straw.children[1] as HTMLDivElement), {
        height: PerspChange,
        top: PerspChange / -2
      });
      gsap.set((straw.lastElementChild as HTMLDivElement), {
        height: PerspChange,
        bottom: PerspChange / -2
      });

      // Move Shine UpAndDown
      gsap.set((straw.firstElementChild as HTMLDivElement).firstElementChild as HTMLDivElement, {
        y: purc * this.strawLift.distance * 3,
        skewY: (negLastDigit * 80) + (purc * 40) - 40
      });

      // remove Powder depending on purcent
      const powd = (straw.nextElementSibling as HTMLDivElement);
      const powdBorder = {
        start: .75,
        end: .15
      }

      if(purc < powdBorder.start && purc > powdBorder.end){
        const powdAnimationRange = powdBorder.start - powdBorder.end;
        const relativePosition = (purc - powdBorder.end) / powdAnimationRange;

        // Calculate new width for powd, starting from 50% and decreasing to 0%
        const powdWidth = (0.5 * relativePosition);

        if(this.lineWidth > powdWidth){
          this.lineWidth = powdWidth;
          gsap.set(powd, {
            width: powdWidth * 100 + "%",
          });
        }
      }

      if(purc < powdBorder.end){
        this.lineWidth = 0;
        gsap.set(powd, {
          autoAlpha: 0
        });
      }

      // Blend in Scroll Down Button depending on mouse position
      gsap.set((this.$refs.strawContainer as HTMLDivElement).lastElementChild, {
        y: lastDigit * this.strawLift.distance,
        autoAlpha: negLastDigit,
      });

    }
  }
});
