import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgeVerification = _resolveComponent("AgeVerification")!
  const _component_nav_mod = _resolveComponent("nav-mod")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_mod = _resolveComponent("footer-mod")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AgeVerification, { onEnter: _ctx.removeBlockScroll }, null, 8, ["onEnter"]),
    _createVNode(_component_nav_mod, {
      ecwid: _ctx.shopHandle,
      count: _ctx.shopHandle.cartCount
    }, null, 8, ["ecwid", "count"]),
    _createVNode(_component_router_view, {
      ecwid: _ctx.shopHandle,
      cmsObject: _ctx.cmsHandle,
      scrollHandle: _ctx.scrollTrigger
    }, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024))
      ]),
      _: 1
    }, 8, ["ecwid", "cmsObject", "scrollHandle"]),
    _createVNode(_component_footer_mod, { cmsObject: _ctx.cmsHandle }, null, 8, ["cmsObject"])
  ], 64))
}