import axios, {AxiosInstance} from "axios";

declare var Ecwid: any;

interface shopKey {
    storeId: number,
    clientId: string,
    tokenId?: string
}

interface param {
    name : string,
    value : string
}

class ShopSystem {

    private isInit: boolean = false;
    public isLoaded: boolean = false;
    private hasCartOverlay: boolean = false;
    private tempScrollPos = 0;

    private timeout : ReturnType<typeof setTimeout> = 0;
    private outSeconds : number = 5;

    public keys : shopKey = {
        storeId : 76433035,
        clientId: 'custom-app-76433035-1'
    };

    private cartNotifyFunction : null | Function = null;

    public init() : Promise<null> {
        return new Promise((resolve, reject) => {

            if(this.isInit){
                console.log('Eine Shop-Instance ist bereits vorhanden');
                resolve(null)
                return;
            }
            this.isInit = true;

            this.timeout = window.setTimeout(() => {
                reject("Timeout");
            }, this.outSeconds * 1000);

            this.scriptLoad().then(() => {
                window.clearTimeout(this.timeout);
                this.initShopSystem().then((apiToken) => {
                    if(!apiToken || apiToken.length < 2) reject("No valid public access token");

                    this.keys.tokenId = apiToken;

                    // set Cart Overlay Scrolling
                    if(typeof((<any>window).ec) !== "undefined") {
                        var ecwidConfig = ((<any>window).ec as any).config as any;
                        ecwidConfig.navigation_scrolling = "DISABLED";

                        /*
                        ecwidConfig.chameleon.font = 'auto';
                        ecwidConfig.chameleon.colors =   {
                            'color-background':'#393538',
                            'color-foreground':'#FFFFFF',
                            'color-link':'#FED935',
                            'color-button':'#FED935',
                            'color-price':'#770000'
                        }
                        */

                        ecwidConfig.disable_all_cookies = true;

                        var ecwidStorefront = ((<any>window).ec as any).storefront as any;
                        ecwidStorefront.shopping_cart_show_sku = "FALSE";
                        ecwidStorefront.shopping_cart_show_weight = "FALSE";
                        Ecwid.refreshConfig();
                    }

                    resolve(null);
                });
            });

        });
    }

    private scriptLoad() : Promise<null> {
        return new Promise((resolve, reject) => {
            const scriptTag = document.createElement("script");
            scriptTag.setAttribute(
                "src",
                `https://app.ecwid.com/script.js?${this.keys.storeId}&data_platform=code`
            );
            scriptTag.setAttribute(
                "data-cfasync",
                "false"
            );
            scriptTag.addEventListener('load', () => {
                resolve(null);
            });
            document.head.appendChild(scriptTag);
        });
    }

    private initShopSystem() : Promise<string>{
        return new Promise((resolve, reject) => {

            if (typeof (Ecwid) == 'object' && typeof (Ecwid.OnAPILoaded) == 'object') {

                Ecwid.config = Ecwid.config || Object();

                Ecwid.OnAPILoaded.add(() => {
                    this.isLoaded = true;
                    // CART API INITIALISIEREN

                    Ecwid.OnPageLoaded.add((page:any) => {
                        console.error("Page loaded successfully", page);
                    });

                    Ecwid.OnPageSwitch.add((page: any) => {
                        if(page.type === "CATEGORY"){
                            // close Cart
                            this.hasCartOverlay = false;
                            window.scrollTo(0, this.tempScrollPos);
                        }else{
                            this.hasCartOverlay = true;
                            this.tempScrollPos = window.scrollY;
                            window.scrollTo(0, 0);
                        }
                    });

                    Ecwid.OnCartChanged.add((cart: any) => {
                        if(this.cartNotifyFunction !== null){
                            this.cartNotifyFunction(cart.productsQuantity);
                        }
                    });

                    console.log('ECWID ERFOLGREICH EINGEBUNDEN');
                    const token = Ecwid.getAppPublicToken(this.keys.clientId);
                    resolve(token);

                });

                Ecwid.init();

            } else {
                reject("ECEWID INIT ERROR");
            }
        });
    }

    public openCart(){
        if(!this.keys.tokenId) return;

        Ecwid.openPage('cart');
    }
    public subscribeToCart(callback: Function){
        this.cartNotifyFunction = callback;
        // get current cart quantity
        Ecwid.Cart.get((cart: any) => {
            callback(cart.productsQuantity);
        });
    }
    public addToCart(product : any){
        if(!this.keys.tokenId) return;

        Ecwid.Cart.addProduct(product);
    }

    public removeFromCart(product: any) {
        Ecwid.Cart.get(function(cart: any) {
            console.log("Cart", cart );
            console.log("Prouct", product);

            const items = cart.items;

            // Item mit Produkt id und variation finden
            // Abspeichern in Variable
            // Aus Warenkorb entfernen
            // Item mit upgedateter Quantity an Warenkorb hinzufügen
            for(var x in items){
                const item = items[x];
                if(item.options.Farbe == product.options.Farbe && item.product.id == product.id){
                    const lastQuantity = item.quantity;
                    Ecwid.Cart.removeProduct(x);
                    if(lastQuantity > 1){
                        product.quantity = lastQuantity - 1;
                        Ecwid.Cart.addProduct(product);
                    }
                    return
                }
            }

        })

        // Ecwid.Cart.removeProduct(0);
    }

    public getProducts(prodId?:number, groupId?:string, sort?:string, offset?:number, onlyEnabled?: boolean, limit?: number) : Promise<any>{
        return new Promise( (resolve, reject) => {

            if(!this.keys.tokenId){
                reject("No Public Token Found");
                return;
            }

            if(!sort) sort = "DEFINED_BY_STORE_OWNER";
            if(!onlyEnabled) onlyEnabled = true;
            if(!limit) limit = 24;
            if(!offset) offset = 0;
            if(!groupId) groupId = "home";

            let params : Array<param> = [];
            params.push({name: "token", value: this.keys.tokenId});
            params.push({name: "sortBy", value: sort});
            params.push({name: "enabled", value: (onlyEnabled)?"true":"false"});
            params.push({name: "limit", value: String(limit)});
            params.push({name: "offset", value: String(offset)});
            if(!prodId && groupId){
                params.push({name: "categories", value: String(groupId)});
            }

            let data = [];
            for(var n in params){
                data.push( params[n].name + "=" + encodeURI(params[n].value) );
            }

            let addedUrl = (!prodId) ? "products" : `products/${prodId}`;

            this.apiRequest.get(addedUrl +"?"+ data.join("&") )
            .then((res: any) => {
                if(res.status == 200){
                    // make all Attributes into assosiative Array
                    if(typeof res.data.items === "undefined"){
                        // api returns one data object
                        this.generatePropertys(res.data);
                    }else{
                        // api returns array of many items
                        for(var x in res.data.items){
                            this.generatePropertys(res.data.items[x]);
                        }
                    }

                    resolve(res.data);
                }
                else reject("Axios Get Error");
            })
            .catch((err:any) => {
                reject(err);
            })
        });
    }

    private generatePropertys(ecwidProduct : any){
        if(typeof ecwidProduct.attributes != "undefined") {
            ecwidProduct.sortedPropertys = this.structAttributes(ecwidProduct.attributes);
        }

        if(typeof ecwidProduct.combinations != "undefined"){
            ecwidProduct.colors = this.structVariations(ecwidProduct.combinations);
        }
    }

    private structAttributes(properties : Array<any>) : Array<any>{
        var sortedArr : Array<any> = [];
        properties.forEach((property : any) => {
            sortedArr[property.id] = {
                title: property.name,
                value: property.value
            }
        });
        return sortedArr;
    }

    private structVariations(combinations : Array<any>) : {[key: string]: Array<any>}{
        var variationArr : {[key: string]: Array<any>};
        variationArr = {};

        combinations.forEach((combi : any, idx) => {
            var colorAttr = this.structAttributes(combi.attributes);

            // check which option the attribute correlates to
            if(combi.options.length >= 1){

                // TODO: REMOVE COMMENT WHEN LIVE
                if(!combi.inStock) return;
                var optionTag = combi.options[0].name;
                if(!variationArr[optionTag]){
                    variationArr[optionTag] = new Array();
                }
                variationArr[optionTag].push({
                    id: variationArr[optionTag].length+1 ,
                    ecwidId: combi.id,
                    title: combi.options[0].value,
                    edition: (!colorAttr[145607957]) ? "" : colorAttr[145607957].value,
                    image: (!combi.hdThumbnailUrl) ? "" : combi.hdThumbnailUrl,
                    infoString: (!colorAttr[145607958]) ? "" : colorAttr[145607958].value,
                    price: combi.defaultDisplayedPrice,
                    comparePrice: combi.compareToPrice,
                    artNr: combi.sku,
                    quantity: combi.quantity,
                    dimension: combi.dimensions,
                    weight: combi.weight
                });
            }

        });
        return variationArr;
    }

    apiRequest: AxiosInstance = axios.create({
        baseURL: `https://app.ecwid.com/api/v3/${this.keys.storeId}/`,
        headers:{
            "Accept": "*/*",
            "Content-Type": "text/json",
            "Cache-Control":"no-cache",
            "Pragma" :  "",
        }
    })
}

export default ShopSystem;