
import {defineComponent, ref} from "vue";
import ShopSystem from "@/modules/ecwid";
import gsap from "gsap";

export default defineComponent({
  name: "NavMod",
  data() {
    return {
      shopReady: false,
      cartCount: 0,
      scrollTop: true
    }
  },
  props: {
    ecwid: {
      type: Object,
      required: true
    }
  },
  watch: {
    ecwid() {
      console.log("ECWID API accessable");
      this.shopReady = true;

      window.addEventListener('scroll', this.scrollEvent);

      (this.$props.ecwid as ShopSystem).subscribeToCart((count: number) => {
        this.animateBanner(count);
      });
    },
  },
  mounted() {
  },
  methods: {
    cartClicked() {
      if (!this.shopReady) return;

      this.$props.ecwid.openCart();
    },
    animateBanner(count: number) {
      if (count > 0 && this.cartCount !== count) {
        if (this.cartCount < 1) {
          // blend in
          gsap.set(".topBanner", {yPercent: -100})
          gsap.to(".topBanner", {yPercent: 0, duration: 0.4, ease: "power1.out", delay: 0.2})

        } else {
          // animate cart icons
          gsap.fromTo(".shopping", {
            xPercent: -5
          }, {
            xPercent: 5,
            repeat: 3,
            duration: .15,
            yoyo: true,
            ease: "Sine.easeInOut",
            onComplete: function () {
              gsap.to(".shopping", {
                xPercent: 0,
                delay: 0.1,
                overwrite: false,
                duration: 0.2,
                ease: "Sine.easeInOut"
              });
            }
          });
        }
      }

      this.cartCount = count;
    },
    scrollEvent(event: Event) {
      this.scrollTop = (window.scrollY < 10);
    }
  }
})
