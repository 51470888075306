import {createApp, provide} from 'vue'
import App from './App.vue'
import router from './router'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';

// library.add(fas, far, fab);

import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faPhoneFlip} from "@fortawesome/free-solid-svg-icons/faPhoneFlip";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";

library.add(faPlus, faMinus, faCheck,
    faChevronDown, faXmark , faChevronLeft, faChevronRight,
    faStar, faSpinner, faShoppingCart,
    faEnvelope, faPhoneFlip, faInstagram);


dom.watch();

import "@/assets/fonts/font.css";

import restClient from "@/modules/restClient";



const app = createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(router, restClient).mount('#app');
