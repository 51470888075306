
import NavMod from "@/components/NavMod.vue";
import FooterMod from "@/components/FooterMod.vue";
import HeroMod from "@/components/HeroMod.vue";
import {defineComponent, ref} from "vue";
import ShopSystem from "@/modules/ecwid";
import pageCMS, {cmsSearch, cmsSetting, param} from "@/modules/cmsRest"
import blendElements from "@/modules/animateTrigger";
import AgeVerification from "@/components/AgeVerification.vue";


export default defineComponent({
  components: {AgeVerification, HeroMod, FooterMod, NavMod },
  data(){
    return {
      shopHandle : new ShopSystem(),
      cmsHandle: new pageCMS(),
      scrollTrigger: new blendElements(),
    }
  },
  mounted(){
    var ShopLoad = new ShopSystem();
    ShopLoad.init().then(() => {
      this.shopHandle = ShopLoad;
    });

    var CmsLoad = new pageCMS();
    CmsLoad.init().then(() => {
      this.cmsHandle = CmsLoad;
    });
  },
  methods:{
  }
});

