<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content content">
      <img class="logoImg" src="@/assets/logo.svg"/>
      <h3>Altersprüfung</h3>
      <p>Bist du älter als 18 Jahre?</p>
      <div class="buttonBox">
        <BaseButton btn-label="Nein" @click="verify(false)" fa-icon="xmark"/>
        <BaseButton btn-label="Ja" @click="verify(true)" fa-icon="check"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "AgeVerification",
  components: {BaseButton},
  data() {
    return {
      showModal: true,
    }
  },
  mounted(){
    if(window.sessionStorage.getItem("ageVerified") === "true"){
      this.showModal = false;
      document.body.classList.remove("noScroll");
    }
  },
  methods: {
    verify(isOldEnough) {
      if (isOldEnough) {
        this.showModal = false;
        // remove body noScroll
        window.sessionStorage.setItem("ageVerified", "true");
        document.body.classList.remove("noScroll");
      } else {
        window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
      }
    }
  }
}
</script>

<style lang="sass">
.modal
  position: fixed
  z-index: 100
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: rgba(0,0,0,0.4)
  backdrop-filter: blur(10px) // add blur effect

  .modal-content
    background-color: $txtDark
    padding: 10vh 10vw
    box-sizing: border-box
    border: 1px solid #888
    margin: 20vh auto
    text-align: center

    .logoImg
      width: 150px
      height: 150px
      margin: 15px

    .buttonBox
      display: flex
      flex-flow: row nowrap
      justify-content: center
      gap: 15px
      margin: 15px 0
      box-sizing: border-box
      @include screen-max($break5)
        flex-flow: column nowrap
        width: 100%



</style>
